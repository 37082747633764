import { BodlEventCallback } from '../bodl';

import { EventEmitter } from './EventEmitter';

export enum BannerViewedEvent {
  CREATE = 'bodl_v1_view_banner',
}

export class BannerEmitter extends EventEmitter {
  viewed(callback: BodlEventCallback) {
    return this.on(BannerViewedEvent.CREATE, callback);
  }
}
