class Logger {
  isEnabled(): boolean {
    return Boolean(window.bodlEvents) && window.bodlEvents?.debug === true;
  }

  debug(message?: unknown, ...optionalParams: unknown[]): void {
    if (this.isEnabled()) {
      /* eslint-disable-next-line no-console  */
      console.debug(message, optionalParams);
    }
  }
}

const logger = new Logger();

export default logger;
