import { BoldEventPayload } from '../bodl';

export interface HistoryEvent {
  name: string;
  payload: BoldEventPayload;
}

export class HistoryManager {
  private events: HistoryEvent[] = [];
  getHistory(name: string) {
    return this.events.filter((event) => event.name === name);
  }

  getFullHistory(): HistoryEvent[] {
    return this.events;
  }

  addEvent(name: string, payload: BoldEventPayload): void {
    const event: HistoryEvent = {
      name,
      payload,
    };

    this.events.push(event);
  }
}
