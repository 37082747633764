import { BodlBackendEvent, getBodl, isBodlEnabled } from '../bodl';

import { EventEmitter } from './EventEmitter';

export class BackendEventsProcessor {
  constructor(private eventEmitter: EventEmitter) {}

  async run() {
    await this.wait();
    this.process();
  }

  wait(): Promise<void> {
    return new Promise((resolve) => {
      window.addEventListener(
        'load',
        () => {
          resolve();
        },
        false,
      );
    });
  }

  process() {
    const events = this.getEvents();

    events.forEach((event) => {
      const eventName = Object.keys(event)[0];
      const payload = event[eventName];

      this.eventEmitter.emit(eventName, payload);
    });
  }

  getEvents(): BodlBackendEvent[] {
    if (!isBodlEnabled()) {
      return [];
    }

    const bodl = getBodl();

    if (bodl?.events.length) {
      return bodl.events;
    }

    return [];
  }
}
