import { BodlEventCallback } from '../bodl';

import { EventEmitter } from './EventEmitter';

export enum AddCartItemEvent {
  CREATE = 'bodl_v1_cart_product_added',
}

export enum RemoveCartItemEvent {
  CREATE = 'bodl_v1_cart_product_removed',
}

export enum CartViewedEvent {
  CREATE = 'bodl_v1_cart_viewed',
}

export class CartEmitter extends EventEmitter {
  addItem(callback: BodlEventCallback) {
    return this.on(AddCartItemEvent.CREATE, callback);
  }

  removeItem(callback: BodlEventCallback) {
    return this.on(RemoveCartItemEvent.CREATE, callback);
  }

  viewed(callback: BodlEventCallback) {
    return this.on(CartViewedEvent.CREATE, callback);
  }
}
