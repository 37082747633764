import { Bodl, BodlEvent, BoldEventPayload } from './types';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export const isBodlEnabled = (): boolean => window && typeof window.bodl !== 'undefined';

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const getBodl = (): Bodl | null => (isBodlEnabled() ? window.bodl : null);

export const addBodlEvent = (event: BodlEvent['name'], payload: BoldEventPayload): void => {
  if (window.bodlEvents) {
    if (!window.bodlEvents.events) {
      window.bodlEvents.events = [];
    }

    window.bodlEvents.events.push({
      event,
      ...payload,
    });
  }
};
