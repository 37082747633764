import { BodlEventCallback, BoldEventPayload } from '../bodl';

import { EventEmitter } from './EventEmitter';

export enum CheckoutBeginEvent {
  CREATE = 'bodl_v1_begin_checkout',
}

export enum OrderPurchasedEvent {
  CREATE = 'bodl_v1_order_purchased',
}

export enum ShippingDetailsProvidedEvent {
  CREATE = 'bodl_v1_shipping_information_added',
}

export enum PaymentDetailsProvidedEvent {
  CREATE = 'bodl_v1_payment_information_added',
}

export class CheckoutEmitter extends EventEmitter {
  emitCheckoutBeginEvent(data: BoldEventPayload) {
    return this.emit(CheckoutBeginEvent.CREATE, data);
  }

  emitOrderPurchasedEvent(data: BoldEventPayload) {
    return this.emit(OrderPurchasedEvent.CREATE, data);
  }

  emitShippingDetailsProvidedEvent(data: BoldEventPayload) {
    return this.emit(ShippingDetailsProvidedEvent.CREATE, data);
  }

  emitPaymentDetailsProvidedEvent(data: BoldEventPayload) {
    return this.emit(PaymentDetailsProvidedEvent.CREATE, data);
  }

  shippingDetailsProvided(callback: BodlEventCallback) {
    return this.on(ShippingDetailsProvidedEvent.CREATE, callback);
  }

  paymentDetailsProvided(callback: BodlEventCallback) {
    return this.on(PaymentDetailsProvidedEvent.CREATE, callback);
  }

  checkoutBegin(callback: BodlEventCallback) {
    return this.on(CheckoutBeginEvent.CREATE, callback);
  }

  orderPurchased(callback: BodlEventCallback) {
    return this.on(OrderPurchasedEvent.CREATE, callback);
  }
}
