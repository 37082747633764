import { EventEmitter as BaseEventEmitter } from 'events';

import { addBodlEvent, BodlEvent, BoldEventPayload } from '../bodl';
import Logger from '../logger/Logger';

import { HistoryEvent, HistoryManager } from './HistoryManager';

export class EventEmitter {
  constructor(private eventEmitter: BaseEventEmitter, private historyManager?: HistoryManager) {}

  emit(eventName: string, payload: BoldEventPayload): boolean {
    Logger.debug(`Emit event ${eventName} with data: `, payload);

    const result = this.eventEmitter.emit(eventName, payload);

    if (result) {
      this.updateBodl(eventName.toString(), payload);
    }

    this.historyManager?.addEvent(eventName, payload);

    return result;
  }

  on(eventName: string, callback: (payload: BoldEventPayload) => void): this {
    this.eventEmitter.on(eventName, callback);
    Logger.debug(`Attached callback ${callback.toString()} on: ${eventName}`);

    if (this.historyManager) {
      const events = this.historyManager.getHistory(eventName);

      if (events.length > 0) {
        this.replayEvents(events, callback);
      }
    }

    return this;
  }

  off(eventName: string, callback: (payload: BoldEventPayload) => void): this {
    this.eventEmitter.off(eventName, callback);
    Logger.debug(`Detached callback ${callback.toString()} on: ${eventName}`);

    return this;
  }

  replayEvents(events: HistoryEvent[], callback: (payload: BoldEventPayload) => void): void {
    events.forEach((event) => {
      callback(event.payload);
    });
  }

  private updateBodl(eventName: BodlEvent['name'], payload: BoldEventPayload) {
    addBodlEvent(eventName, payload);
  }
}
