import { BodlEventCallback } from '../bodl';

import { EventEmitter } from './EventEmitter';

export enum PageViewedEvent {
  CREATE = 'bodl_v1_product_page_viewed',
}

export enum CategoryViewedEvent {
  CREATE = 'bodl_v1_product_category_viewed',
}

export enum SearchPerformedEvent {
  CREATE = 'bodl_v1_keyword_search',
}

export class ProductEmitter extends EventEmitter {
  pageViewed(callback: BodlEventCallback) {
    return this.on(PageViewedEvent.CREATE, callback);
  }

  categoryViewed(callback: BodlEventCallback) {
    return this.on(CategoryViewedEvent.CREATE, callback);
  }

  searchPerformed(callback: BodlEventCallback) {
    return this.on(SearchPerformedEvent.CREATE, callback);
  }
}
