import { BodlEventCallback, BoldEventPayload } from '../bodl';

import { EventEmitter } from './EventEmitter';

export enum ConsentLoadedEvent {
  CREATE = 'bodl_v1_consent_loaded',
}

export enum ConsentUpdatedEvent {
  CREATE = 'bodl_v1_consent_updated',
}

export class ConsentEmitter extends EventEmitter {
  emitConsentLoadedEvent(data: BoldEventPayload) {
    return this.emit(ConsentLoadedEvent.CREATE, data);
  }

  emitConsentUpdatedEvent(data: BoldEventPayload) {
    return this.emit(ConsentUpdatedEvent.CREATE, data);
  }

  loaded(callback: BodlEventCallback) {
    return this.on(ConsentLoadedEvent.CREATE, callback);
  }

  updated(callback: BodlEventCallback) {
    return this.on(ConsentUpdatedEvent.CREATE, callback);
  }
}
